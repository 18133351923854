import {
  getDatabase,
  ref,
  set,
  update,
  query,
  equalTo,
  orderByChild,
  get,
} from "firebase/database";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import format from "date-fns/format";
import helpers from "@/helpers/global";
import _ from "lodash";

import { Microsoft } from "../types";

const OAUTH_PROVIDER_NAME = "microsoft";
const DATETIME_FORMAT = "yyy-MM-dd HH:mm:ss";

export default {
  namespaced: true,
  state: {
    error: false,
    isMicrosoftBinding: false,
  },
  mutations: {
    error(state: Microsoft, error: boolean): any {
      state.error = error;
    },
    isMicrosoftBinding(state: Microsoft, isMicrosoftBinding: boolean): any {
      state.isMicrosoftBinding = isMicrosoftBinding;
    },
  },
  getters: {
    isMicrosoftBinding(state: Microsoft): boolean {
      return state.isMicrosoftBinding;
    },
  },
  actions: {
    isMicrosoftBinding(
      { commit }: { commit: any },
      isMicrosoftBinding: boolean
    ): any {
      commit("isMicrosoftBinding", isMicrosoftBinding);
    },
    async upsertAccount(
      { commit }: { commit: any },
      result: {
        user: {
          email: string;
          displayName: string;
          photoURL: string;
          metadata: any;
          accessToken: string;
          stsTokenManager: any;
          emailVerified: boolean;
        };
      }
    ): Promise<any> {
      const db = getDatabase();

      let email = result.user.email;

      // @fixed hotmail
      // @note email: rcoco66_hotmail.com#ext#@rcoco66hotmail.onmicrosoft.com
      email = email.split("#")[0];
      email = email.replace("_hotmail.com", "@hotmail.com");

      email = helpers.filterEmail(email);

      // @note don't check email for microsoft format because it has . and # sign
      // if (!helpers.validateEmail(email)) {
      //   commit("auth/errorMessage", "Invalid email address.", { root: true });
      //   return false;
      // }

      // microsfot"s email key
      const emailKey = helpers.filterPath(email);
      const path = `oauth_users/${OAUTH_PROVIDER_NAME}`;
      const user = (
        await get(query(ref(db, path), orderByChild("email"), equalTo(email)))
      ).val();

      const displayName = result.user.displayName;
      const chunkNames = displayName.split(" ");
      const firstName = chunkNames[0] || "";
      const lastName = chunkNames[chunkNames.length - 1] || "";

      if (user) {
        update(ref(db, path + "/" + emailKey), {
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          metadata: result.user.metadata,
          accessToken: result.user.accessToken,
          stsTokenManager: result.user.stsTokenManager,
          updatedAt: format(new Date(), DATETIME_FORMAT),
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });

        // set user
        update(ref(db, "users/" + emailKey), {
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          updatedAt: format(new Date(), DATETIME_FORMAT),
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });
      } else {
        const createdAt = format(new Date(), DATETIME_FORMAT);

        // set user to oauth microsoft users
        set(ref(db, path + "/" + emailKey), {
          email: email,
          emailVerified: result.user.emailVerified,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          metadata: result.user.metadata,
          accessToken: result.user.accessToken,
          stsTokenManager: result.user.stsTokenManager,
          createdAt: createdAt,
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });

        // @deprecated set user
        update(ref(db, "users/" + emailKey), {
          email: email,
          displayName: result.user.displayName,
          firstName: firstName,
          lastName: lastName,
          photoURL: result.user.photoURL,
          createdAt: createdAt,
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });
      }
    },
    async loginByMicrosoft(
      { commit }: { commit: any },
      authData: { email: string; success: any; error: any }
    ): Promise<any> {
      const auth = getAuth();

      // Microsoft
      // @see https://firebase.google.com/docs/auth/web/microsoft-oauth#web-version-9

      const provider = new OAuthProvider("microsoft.com");

      provider.setCustomParameters({
        // Force re-consent.
        prompt: "consent",
        // Target specific email with login hint.
        // login_hint: 'rcoco66@hotmail.com', //authData.email,
        tenant: "a8006606-9069-44d2-892a-1ccf64c00e1a",
      });

      // provider.addScope("mail.read");
      // provider.addScope("calendars.read");

      await signInWithPopup(auth, provider)
        .then(async (result: any) => {
          if (_.isFunction(authData.success)) {
            authData.success(result);
            commit("error", false);
          }
        })
        .catch((error: any) => {
          if (_.isFunction(authData.error)) {
            authData.error(error);
          }
        });
    },
    async login(
      { commit, dispatch }: { commit: any; dispatch: any },
      authData: { email: string; validate?: any }
    ): Promise<any> {
      const options = {
        success: async (result: any) => {
          // Microsoft (for @outlook.com and @hotmail.com addresses
          // @see https://firebase.google.com/docs/auth/users
          let email = String(result.user.email);

          // @fixed hotmail
          // @note email: rcoco66_hotmail.com#ext#@rcoco66hotmail.onmicrosoft.com
          if (email.indexOf("#") !== -1) {
            email = email.split("#")[0];
            email = email.replace("_hotmail.com", "@hotmail.com");
          }

          email = helpers.filterEmail(email);
          const valid = helpers.validateEmail(email);
          if (valid) {
            if (authData.validate && _.isFunction(authData.validate)) {
              const valid = await authData.validate(email);
              if (!valid) return false;
            }

            const hasUser = await dispatch("user/hasUser", email, {
              root: true,
            });

            if (hasUser) {
              const db = getDatabase();
              const emailKey = helpers.filterPath(email);
              const microsoftUserPath = `oauth_users/microsoft/${emailKey}`;
              const microsoftUser = (
                await get(query(ref(db, microsoftUserPath)))
              ).val();
              if (microsoftUser) {
                await dispatch("upsertAccount", result);
              }
              dispatch("auth/loginAsUser", { email: email }, { root: true });
            } else {
              // Collect First Name and Last Name information to pre-fill the sign up form.
              dispatch(
                "CreateAccountForm/setOAuthResultDetails",
                { oAuthProvider: "microsoft", oAuthResult: result },
                {
                  root: true,
                }
              );
              dispatch("auth/setSignupEmail", { email: email }, { root: true });
              dispatch("CreateAccountForm/setCreateStep", "create_detail", {
                root: true,
              });
            }
          } else {
            // not matching auth user
            const errorMsg = `The account you attempted to log in with is not registered with Happily. Please try again or contact your admin.`;
            commit("auth/errorMessage", errorMsg, { root: true });
          }
        },
        error: (error: any) => {
          console.log(error, "error");
          commit("error", error);
        },
      };
      dispatch("loginByMicrosoft", { ...authData, ...options });
    },
    async signup(
      { commit, dispatch }: { commit: any; dispatch: any },
      authData: { email: string; validate?: any }
    ): Promise<any> {
      // @todo user can"t login with this method must use invitation
      commit("error", false);
      const newAuthData = _.assign({}, authData);
      newAuthData.validate = async (email: string): Promise<any> => {
        const valid = !(await dispatch("user/hasUser", email, {
          root: true,
        }));
        if (!valid) {
          commit(
            "auth/errorMessage",
            "An account with the given email already exists",
            { root: true }
          );
        }
        return valid;
      };
      dispatch("login", newAuthData);
    },
    async bindAccount(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      authData: { email: string; success?: any; error?: any }
    ): Promise<any> {
      const noop = () => {
        /* noop */
      };
      const errorHandler = _.isFunction(authData.error) ? authData.error : noop;
      const options = {
        success: async (result: any) => {
          // Microsoft (for @outlook.com and @hotmail.com addresses
          // @see https://firebase.google.com/docs/auth/users
          let email = _.get(result, "user.email");

          // @fixed hotmail
          // @note email: rcoco66_hotmail.com#ext#@rcoco66hotmail.onmicrosoft.com
          if (email.indexOf("#") !== -1) {
            email = email.split("#")[0];
            email = email.replace("_hotmail.com", "@hotmail.com");
          }

          const emailTo = helpers.filterEmail(email);
          const valid = helpers.validateEmail(email);
          //*
          if (valid) {
            const email = _.get(result, "user.email");
            const hasUser = await dispatch("user/hasUser", email, {
              root: true,
            });
            if (hasUser) {
              const errorMsg = "An account with the given email already exists";
              commit("auth/errorMessage", errorMsg, { root: true });
              errorHandler(errorMsg);
              return false;
            }

            // const emailKey = helpers.filterPath(email);

            const currentUser = _.get(rootState, "user.user");
            if (!currentUser) {
              const errorMsg =
                "You are not a guest account. Please login with guest account to verify your email";
              commit("errorMessage", errorMsg);
              errorHandler(errorMsg);
              return;
            }

            if (!currentUser.isGuest) {
              const errorMsg =
                "You are not a guest account. Please login with guest account to verify your email";
              commit("errorMessage", errorMsg);
              errorHandler(errorMsg);
              return;
            }

            // update user
            await dispatch(
              "user/saveUser",
              {
                email: currentUser.email,
                emailTo: emailTo,
              },
              { root: true }
            );
            _.set(rootState, "user.user.emailTo", email);

            await dispatch(
              "guest/transferGuestToUser",
              {
                guestEmail: currentUser.email,
                email: emailTo,
              },
              { root: true }
            );

            await dispatch("upsertAccount", result);

            await dispatch(
              "auth/loginAsUser",
              { email: emailTo },
              {
                root: true,
              }
            );
          } else {
            const errorMsg = `The account you attempted to log in with is not registered with MyCulture. Please try again or contact your admin.`;
            commit("auth/errorMessage", errorMsg, { root: true });
            errorHandler(errorMsg);
          }
          //*/
        },
        error: (error: any) => {
          console.log(error, "error");
          if (_.isFunction(authData.error)) {
            authData.error(error);
          }
        },
      };
      dispatch("loginByMicrosoft", options);
    },
  },
};
