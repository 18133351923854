// Features: Guest
// 1. Check if the user is guest
// 2. Set the user as guest
// 3. Get the user's guest status
// 4. Route: disable signup page, if signup page is accessed redirect to create assessment page

import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

import { getDatabase, ref, set } from "firebase/database";

import _ from "lodash";
import helpers from "@/helpers/global";

interface Guest {
  isGuest: boolean;
  routeTo: any;
}

interface State {
  commit?: any;
  dispatch?: any;
  rootState?: any;
}

export default {
  namespaced: true,
  state: {
    isGuest: false,
    routeTo: "/",
  },
  mutations: {
    isGuest(state: Guest, isGuest: boolean): any {
      state.isGuest = isGuest;
    },
    commit(state: Guest, routeTo: { name?: string; params?: any }): any {
      state.routeTo = routeTo;
    },
  },
  getters: {
    isGuest(state: Guest): boolean {
      return state.isGuest;
    },
    routeTo(state: Guest): any {
      return state.routeTo;
    },
  },
  actions: {
    async init({ commit, dispatch, rootState }: State): Promise<any> {
      // @todo is user, is guest or not logged in
      const user = _.get(rootState, "auth.user", null);
      let isGuest = true;
      if (!user) {
        await dispatch("createGuest");
      } else {
        isGuest = _.get(user, "isGuest", false);
      }
      commit("isGuest", isGuest);
    },
    async createGuest({ commit, dispatch, rootState }: State): Promise<any> {
      let created = false;
      do {
        const email = helpers.generateString(32) + "@guest.myculture.ai";
        const isUser = await dispatch("user/hasUser", email, { root: true });
        if (isUser) continue;

        const now = helpers.now();
        const userDetails: any = {
          autoGenerateForm: false,
          color: "#000000",
          companyLogoUrl: "",
          companyName: "",
          createdAt: now,
          displayName: "Guest Account",
          email: email, // @note
          firstName: "Guest",
          guidedTourCreateDone: false,
          guidedTourCreateEnabled: true,
          jobTitle: "",
          lang: "en",
          language: "en",
          lastName: "Account",
          photoURL: "",
          referral: "email",
          isGuest: true,
        };

        await dispatch("user/createUser", userDetails, {
          root: true,
        });
        _.set(rootState, "user.user", userDetails);

        await dispatch("team/setup", userDetails, {
          root: true,
        });

        await dispatch(
          "auth/loginAsUser",
          {
            email,
            callback: () => {
              /* Prevent to redirect */
            },
          },
          { root: true }
        );
        _.set(rootState, "auth.user", userDetails);

        created = true;
      } while (!created);

      commit("isGuest", true);
    },
    async transferGuestToUser(
      { commit, dispatch }: { commit: any; dispatch: any },
      { email, guestEmail }: { email: string; guestEmail: string }
    ): Promise<any> {
      const guestUser = await dispatch("user/getUser", guestEmail, {
        root: true,
      });
      // const targetUser = await dispatch("user/getUser", email, {
      //   root: true,
      // });

      // @todo transfer assessment templates, assessments, quota
      //
      //

      const emailFrom = guestEmail;
      const emailTo = email;
      const emailFromKey = helpers.emailKey(emailFrom);
      const emailToKey = helpers.emailKey(emailTo);

      const now = helpers.now();

      const fs = getFirestore();
      let q, querySnapshot;

      // UPDATE: teams
      q = query(collection(fs, "teams"), where("owner", "==", emailFrom));
      querySnapshot = await getDocs(q);
      for (const document of querySnapshot.docs) {
        // console.log(doc.id, "doc.id");
        // console.log(doc.data(), "doc.data()");
        const teamData = document.data();

        const memberEmails = [];
        const members = _.get(teamData, "members", []);
        for (const i in members) {
          const member = members[i];
          if (member.email === emailFrom) {
            members[i].email = emailTo;
          }
          memberEmails.push(members[i].email);
        }
        const updatedData: any = {
          creator: emailTo,
          owner: emailTo,
          member_emails: memberEmails,
          members: members,
          created_at: now,
          guest_created_at: teamData.created_at,
        };
        // console.log(updatedData, "teamData: updatedData");
        await setDoc(doc(fs, "teams", document.id), updatedData, {
          merge: true,
        });
      }

      // UPDATE: quota of team used for owner can be transferred to new owner, leave from team after assign new owner
      q = query(collection(fs, "quota"), where("owner", "==", emailFrom));
      querySnapshot = await getDocs(q);
      for (const document of querySnapshot.docs) {
        // console.log(document.id, "document.id");
        const quotaData = document.data();
        const updatedData: any = {
          creator: emailTo,
          owner: emailTo,
          created_at: now,
          guest_created_at: quotaData.created_at,
        };
        // console.log(updatedData, "quotaData: team");
        await setDoc(doc(fs, "quota", document.id), updatedData, {
          merge: true,
        });
      }

      // CREATE && DELETE: personal quota
      const docRef = doc(fs, "quota", emailFromKey);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const quotaData = docSnap.data();
        quotaData.guest_created_at = quotaData.created_at;
        quotaData.created_at = now;

        await setDoc(doc(fs, "quota", emailToKey), quotaData, {
          merge: false,
        });

        await deleteDoc(doc(fs, "quota", emailFromKey));
      }

      // UPDATE: quota history
      q = query(
        collection(fs, "quota_history"),
        where("owner", "==", emailFrom)
      );
      querySnapshot = await getDocs(q);
      for (const document of querySnapshot.docs) {
        const data = document.data();
        const updatedData: any = {
          owner: emailTo,
          created_at: now,
          guest_created_at: data.created_at,
        };
        await setDoc(doc(fs, "quota_history", document.id), updatedData, {
          merge: true,
        });
      }

      // UPDATE: assessments
      q = query(
        collection(fs, "assessments"),
        where("creator", "==", emailFrom)
      );
      querySnapshot = await getDocs(q);
      for (const document of querySnapshot.docs) {
        const data = document.data();
        const updatedData: any = {
          owner: emailTo,
          created_at: now,
          guest_created_at: data.created_at,
        };
        await setDoc(doc(fs, "assessments", document.id), updatedData, {
          merge: true,
        });
      }

      // UPDATE: assessment templates
      q = query(
        collection(fs, "assessment_templates"),
        where("creator", "==", emailFrom)
      );
      querySnapshot = await getDocs(q);
      for (const document of querySnapshot.docs) {
        const data = document.data();
        const updatedData: any = {
          creator: emailTo,
          created_at: now,
          guest_created_at: data.created_at,
        };
        await setDoc(
          doc(fs, "assessment_templates", document.id),
          updatedData,
          { merge: true }
        );
      }

      // DELETE: onboarding, create on cloud function
      q = query(collection(fs, "onboarding"), where("email", "==", emailFrom));
      querySnapshot = await getDocs(q);
      for (const document of querySnapshot.docs) {
        await deleteDoc(doc(fs, "onboarding", document.id));
      }

      // CREATE && DELETE: user
      const userDetails: any = _.cloneDeep(guestUser);
      userDetails.email = emailTo;
      userDetails.isGuest = false;
      delete userDetails.emailTo;
      await dispatch("user/createUser", userDetails, {
        root: true,
      });

      // delete guest user
      const db = getDatabase();
      set(ref(db, `users/${emailFromKey}`), null);

      // @todo delete auth user login with password

      commit("isGuest", false);
    },
  },
};
