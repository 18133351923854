
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
import _ from "lodash";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  // uploadBytesResumable,
} from "firebase/storage";

export default defineComponent({
  name: "Navbar",
  async created() {
    await this.$store.dispatch("user/loadCurrentUser", null, { root: true });
  },
  async mounted() {
    // binding team load in quota/load
    await this.$store.dispatch("quota/load", this.user, { root: true });

    const user = this.$store.getters["user/user"] || {};
    let hasTeam = false;
    let isPersonal = true;
    let team = null;
    if (user && user.teamId) {
      await this.$store.dispatch(
        "team/getTeam",
        { teamId: user.teamId },
        { root: true }
      );
      team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;
        this.isPersonal = isPersonal;
      }
    }
    this.isUserMode = !(hasTeam && !isPersonal);

    // Can choose which team to switch to (If there is more than 1 team)
    await this.$store.dispatch("team/getTeams", null, { root: true });
    const teams = this.$store.getters["team/teams"];

    // first team is "personal" and then is the list of teams so use length > 2
    this.moreThanOneTeam = teams && teams.length > 2;

    this.noTeam = teams && teams.length < 2;

    if (this.moreThanOneTeam) {
      this.selectableTeams = _.sortBy(
        _.filter(teams, (team) => {
          return !team.personal;
        }),
        "name"
      );
    }

    this.currentTeamId = String(user.teamId);

    if (user.teamId) {
      this.$store.dispatch(
        "team/observeTeam",
        { teamId: user.teamId },
        { root: true }
      );
    }
  },
  watch: {
    teamError(error) {
      if (error && this.teamForceReload) {
        ElMessageBox.alert(error, (this as any).$t("Error"), {
          customClass: "error-message-box",
          showClose: false,
          confirmButtonText: (this as any).$t("popup.optional.Got_it"),
          callback: () => {
            // reload
            window.location.reload();
          },
        });
      }
    },
  },
  computed: {
    isGuest(): boolean {
      return _.get(this.$store.getters["user/user"], "isGuest", true);
    },
    emailTo(): string {
      return _.get(this.$store.getters["user/user"], "emailTo", "");
    },
    companyColor() {
      const defaultCompanyColor = "#1B1C1E";
      const path = this.isUserMode ? "user/user" : "team/team";
      const user: any = this.$store.getters[path] || {};
      if (user && user.color) {
        return user.color;
      }
      return defaultCompanyColor;
    },
    userDisplayName(): string {
      const team = this.$store.getters["team/team"];
      if (team) {
        if (!team.personal) {
          return team.name;
        }
      }
      return helpers.getUserDisplayName(this.$store.getters["auth/user"]);
    },
    paymentEnabled(): boolean {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    quota(): any {
      return this.$store.getters["quota/data"];
    },
    user(): any {
      return this.$store.getters["auth/user"];
    },
    companyLogoUrl(): any {
      const defaultLogoUrl = "";
      // const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      const userDataEncoded: any = localStorage.getItem("userData");

      const companyLogoUrl = this.isUserMode
        ? this.$store.getters["user/companyLogoUrl"]
        : this.$store.getters["team/companyLogoUrl"];
      if (userDataEncoded) {
        try {
          const userData = JSON.parse(atob(userDataEncoded) || "{}");
          if (userData.email) {
            return companyLogoUrl;
          } else {
            return defaultLogoUrl;
          }
        } catch (e: any) {
          console.error(e);
        }
      } else {
        return defaultLogoUrl;
      }
      return "";
    },
    isImage(): any {
      return (
        this.selectedFile && (this.selectedFile as any).type.includes("image")
      );
    },
    teamError(): string {
      return this.$store.getters["team/error"];
    },
    teamForceReload(): string {
      return this.$store.getters["team/forceReload"];
    },
  },
  data() {
    return {
      isUserMode: false,
      isPersonal: false,
      moreThanOneTeam: false,
      noTeam: true,
      selectableTeams: [] as any,
      currentTeamId: "",
      companyImage: "",
      helpAndSupportModalStatus: false,
      needHelpMessage: "",
      selectedFile: null,
      imageFile: "",
      supportTicketUploadedFile: new File([], ""),
      supportTicketUploadedFileDataUrl: "",
      previewUrl: "",
      errors: {
        needHelpMessage: false,
      },
      verifiedAccountModalStatus: false,
    };
  },
  methods: {
    cover(url) {
      if (url !== "") {
        // You can check any matching expression.
        try {
          url = require("@/assets/icons/" + url);
        } catch (e) {
          url = require("@/assets/icons/help.png"); // I used a default image.
        }
      } else url = require("@/assets/icons/help.png"); // Default image.
      return url;
    },
    async handleClickAuthProvider(provider: string) {
      const authData = {
        email: null,
        provider: provider,
        error: (errorMsg: any) => {
          ElMessage.error(errorMsg);
        },
      };
      await this.$store.dispatch("auth/bindAccount", authData, { root: true });
    },
    async verifyWithEmailCode() {
      const user = this.$store.getters["user/user"];
      const email = _.get(user, "emailTo");
      await this.$store.dispatch("auth/verifyGuestWeb", {
        email: email,
      });
      if (this.$store.getters["auth/errorMessage"]) {
        ElMessage.error(this.$store.getters["auth/errorMessage"]);
      } else if (this.$store.getters["auth/error"]) {
        ElMessage.error(this.$store.getters["auth/error"]);
      } else {
        this.verifiedAccountModalStatus = false;
        this.$router.push({
          name: "AccountVerify",
          query: {
            email: email,
            flow: "verify",
          },
        });
      }
    },
    menuActiveStyleBar() {
      if ("core-values" == this.$route.meta.navbarName) {
        return "menu-4";
      } else if ("assessments" == this.$route.meta.navbarName) {
        return "menu-2";
      } else if ("respondents" == this.$route.meta.navbarName) {
        return "menu-3";
      }
      return "menu-4";
    },
    async handleCommand(command: string) {
      let teamId = "";
      if (command.indexOf("switch_user_to_team_") == 0) {
        teamId = command.split("switch_user_to_team_")[1];
        command = "switch_user_to_team";
      }

      switch (command) {
        case "my_core_values":
          this.onClickMyCoreValues();
          break;
        case "job_role_generator":
          this.$router.push({ name: "JobRoleGenerator" });
          break;
        case "job_post_generator":
          this.$router.push({ name: "JobPostGenerator" });
          break;
        case "interview_guide":
          this.$router.push({ name: "InterviewGuide" });
          break;
        case "career_path_tracker":
          this.$router.push({ name: "CareerPathTracker" });
          break;
        case "okr_generator":
          this.$router.push({ name: "OkrGenerator" });
          break;
        case "performance_improvement":
          this.$router.push({ name: "PerformanceImprovement" });
          break;
        case "num_day_plan":
          this.$router.push({ name: "NumDayPlan" });
          break;
        case "logout":
          await this.$store.dispatch("auth/logout");
          window.location.href = "/login";
          break;
        case "my_account":
          this.$router.push({ name: "MyAccount" });
          break;
        case "help_n_support":
          this.openHelpAndSupport();
          break;
        case "switch_user_to_team":
          await this.switchUserToTeam(teamId);
          break;
        case "switch_team_to_user":
          await this.switchTeamToUser();
          break;
      }
    },
    async switchToTeam(teamId: string) {
      const user = this.$store.getters["user/user"] || {};
      if (user.teamId == teamId) return;
      await this.$store.dispatch("user/changeTeam", teamId, { root: true });

      if (this.$router.currentRoute.value.name == "AssessmentsEdit") {
        window.location.href = "/assessments";
      } else {
        window.location.reload();
      }
    },
    async switchUserToTeam(teamId?: string) {
      // console.log(`switchUserToTeam: ${teamId}`);
      // goto personal team
      if (!teamId) {
        const teams = this.$store.getters["team/teams"];
        if (teams && teams.length > 0) {
          const defaultTeam = _.find(teams, { personal: false });
          if (defaultTeam) {
            teamId = defaultTeam._id;
          }
        }
      }
      if (teamId) {
        await this.switchToTeam(teamId);
      }
    },
    async switchTeamToUser() {
      // await this.$store.dispatch("team/getTeams", null, { root: true });
      const teams = this.$store.getters["team/teams"];
      if (teams && teams.length > 0) {
        const personalTeam = _.find(teams, { personal: true });
        if (personalTeam) {
          const teamId = personalTeam._id;
          if (teamId) {
            await this.switchToTeam(teamId);
          }
        }
      }
    },
    gotoPaymentPage() {
      this.$router.push({
        name: "MyAccountWithTab",
        params: { tab: "plan-billing" },
      });
    },
    onClickMyCoreValues() {
      const user = this.$store.getters["user/user"];
      const to = helpers.getMyCoreValuesRoute(user);
      this.$router.push(to);
    },
    handleFileUpload(event: any) {
      const file = event.target.files[0];
      this.selectedFile = file;

      if (file && file.type.includes("image")) {
        this.previewUrl = URL.createObjectURL(file);
        this.handleAvatarSuccess({ file });
      } else {
        this.previewUrl = "";
      }
    },
    handleAvatarSuccess(res: any) {
      this.imageFile = URL.createObjectURL(res.file);

      const reader = new FileReader();
      reader.addEventListener("load", (/*event: ProgressEvent*/) => {
        this.supportTicketUploadedFile = res.file;
        this.supportTicketUploadedFileDataUrl = String(reader.result);
      });
      reader.readAsDataURL(res.file);
    },
    openHelpAndSupport() {
      this.helpAndSupportModalStatus = true;
    },
    // @todo move to store
    async submitHelpAndSupport() {
      this.errors.needHelpMessage = false;
      if (!this.needHelpMessage.length) {
        this.errors.needHelpMessage = true;
        return;
      }

      await this.$store.dispatch(
        "assessmentTemplate/getList",
        { page: 1 },
        { root: true }
      );
      const items = this.$store.getters["assessmentTemplate/items"] || [];

      const supportTicketDetails = {
        message: this.needHelpMessage,
        has_attachment: false,
        company_name: this.user.companyName || "",
        fullname: this.user.displayName || "",
        email: this.user.email || "",
        assessments_total: items.length,
        credits_remaining: this.quota.unlimited
          ? helpers.t("myAccount.Unlimited")
          : this.quota.quota_remaining || 0,
        created_at: helpers.now(),
      } as any;

      const createSupportTicket = async (supportTicketDetails: any) => {
        // add data to firestore
        const fs = getFirestore();
        addDoc(collection(fs, "support_tickets"), supportTicketDetails);

        // reset all values
        this.needHelpMessage = "";
        this.selectedFile = null;

        // show success message
        ElMessage({
          type: "success",
          message: (this as any).$t(
            "myAccount.Thank_you_for_submitting_your_messasge"
          ),
        });
      };

      this.helpAndSupportModalStatus = false;

      const file = this.supportTicketUploadedFile;
      const dataUrl = this.supportTicketUploadedFileDataUrl;
      if (dataUrl && file) {
        const storage = getStorage();
        const email = this.user.email;
        const emailKey = helpers.emailKey(email);
        const prefix = "support-tickets";
        const guid = helpers.guid();
        let filename = file.name;
        if (filename.indexOf(".") !== -1) {
          const chunks = filename.split(".");
          const ext = chunks.pop();
          filename = chunks.join(".") + "-" + guid + "." + ext;
        }
        const path = `images/users/${emailKey}/${prefix}-${filename}`;
        const storageRef = ref(storage, path);
        uploadString(storageRef, dataUrl, "data_url").then(async (snapshot) => {
          // supportTicketDetails.uploaded_image_url = snapshot.metadata.fullPath;
          getDownloadURL(snapshot.ref).then(async (url) => {
            supportTicketDetails.uploaded_image_url = url;
            supportTicketDetails.has_attachment = true;
            await createSupportTicket(supportTicketDetails);
          });
        });
      } else {
        await createSupportTicket(supportTicketDetails);
      }
    },
  },
});
