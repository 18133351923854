import {
  getDatabase,
  ref,
  set,
  update,
  query,
  equalTo,
  orderByChild,
  get,
} from "firebase/database";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import format from "date-fns/format";
import helpers from "@/helpers/global";
import _ from "lodash";

import { Google } from "../types";

const OAUTH_PROVIDER_NAME = "google";
const DATETIME_FORMAT = "yyy-MM-dd HH:mm:ss";

export default {
  namespaced: true,
  state: {
    error: false,
    isGoogleBinding: false,
  },
  mutations: {
    error(state: Google, error: boolean): any {
      state.error = error;
    },
    isGoogleBinding(state: Google, isGoogleBinding: boolean): any {
      state.isGoogleBinding = isGoogleBinding;
    },
  },
  getters: {
    isGoogleBinding(state: Google): boolean {
      return state.isGoogleBinding;
    },
  },
  actions: {
    isGoogleBinding(
      { commit }: { commit: any },
      isGoogleBinding: boolean
    ): any {
      commit("isGoogleBinding", isGoogleBinding);
    },
    async upsertAccount(
      { commit }: { commit: any },
      result: {
        user: {
          email: string;
          displayName: string;
          photoURL: string;
          metadata: any;
          accessToken: string;
          stsTokenManager: any;
          emailVerified: boolean;
        };
      }
    ): Promise<any> {
      const db = getDatabase();

      const email = helpers.filterEmail(result.user.email);
      if (!helpers.validateEmail(email)) {
        commit("errorMessage", "Invalid email address.");
        return false;
      }

      // google"s email key
      const emailKey = helpers.filterPath(email);
      const path = `oauth_users/${OAUTH_PROVIDER_NAME}`;
      const user = (
        await get(query(ref(db, path), orderByChild("email"), equalTo(email)))
      ).val();

      const displayName = result.user.displayName;
      const chunkNames = displayName.split(" ");
      const firstName = chunkNames[0] || "";
      const lastName = chunkNames[chunkNames.length - 1] || "";

      if (user) {
        update(ref(db, path + "/" + emailKey), {
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          metadata: result.user.metadata,
          accessToken: result.user.accessToken,
          stsTokenManager: result.user.stsTokenManager,
          updatedAt: format(new Date(), DATETIME_FORMAT),
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });

        // set user
        update(ref(db, "users/" + emailKey), {
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          updatedAt: format(new Date(), DATETIME_FORMAT),
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });
      } else {
        // set user to oauth google users
        set(ref(db, path + "/" + emailKey), {
          email: email,
          emailVerified: result.user.emailVerified,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          metadata: result.user.metadata,
          accessToken: result.user.accessToken,
          stsTokenManager: result.user.stsTokenManager,
          createdAt: format(new Date(), DATETIME_FORMAT),
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });

        // @deprecated set user
        update(ref(db, "users/" + emailKey), {
          email: email,
          displayName: result.user.displayName,
          firstName: firstName,
          lastName: lastName,
          photoURL: result.user.photoURL,
          createdAt: format(new Date(), DATETIME_FORMAT),
        })
          .then(() => {
            // Data saved successfully!
            commit("error", false);
          })
          .catch((error) => {
            // The write failed...
            console.log(error, "error");
          });
      }
    },
    async loginByGoogle(
      { commit }: { commit: any },
      authData: { email: string; success: any; error: any }
    ): Promise<any> {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");

      // @seee https://github.com/firebase/firebaseui-web/issues/61
      provider.setCustomParameters({
        prompt: "select_account",
      });

      await signInWithPopup(auth, provider)
        .then(async (result: any) => {
          if (_.isFunction(authData.success)) {
            authData.success(result);
            commit("error", false);
          }
        })
        .catch((error: any) => {
          if (_.isFunction(authData.error)) {
            authData.error(error);
          }
        });
    },
    // @todo can't login. user must binding account or use another policy
    async login(
      { commit, dispatch }: { commit: any; dispatch: any },
      authData: { email: string; validate?: any }
    ): Promise<any> {
      const options = {
        success: async (result: any) => {
          const email = helpers.filterEmail(result.user.email);
          const valid = helpers.validateEmail(email);
          if (valid) {
            // @see https://trello.com/c/37t9JFXS/1289-assessment-as-a-visitor-i-want-to-be-able-to-sign-up-for-an-account-so-that-i-can-start-creating-and-delivering-assessments-to-c
            const email = result.user.email;

            if (authData.validate && _.isFunction(authData.validate)) {
              const valid = await authData.validate(email);
              if (!valid) return false;
            }

            const hasUser = await dispatch("user/hasUser", email, {
              root: true,
            });

            if (hasUser) {
              const db = getDatabase();
              const emailKey = helpers.filterPath(email);
              const googleUserPath = `oauth_users/google/${emailKey}`;
              const googleUser = (
                await get(query(ref(db, googleUserPath)))
              ).val();
              if (googleUser) {
                await dispatch("upsertAccount", result);
              }
              dispatch("auth/loginAsUser", { email: email }, { root: true });
            } else {
              // Collect First Name and Last Name information to pre-fill the sign up form.
              dispatch(
                "CreateAccountForm/setOAuthResultDetails",
                { oAuthProvider: "google", oAuthResult: result },
                {
                  root: true,
                }
              );
              dispatch("auth/setSignupEmail", { email: email }, { root: true });
              dispatch("CreateAccountForm/setCreateStep", "create_detail", {
                root: true,
              });
            }
          } else {
            // not matching auth user
            const errorMsg = `The account you attempted to log in with is not registered with Happily. Please try again or contact your admin.`;
            commit("auth/errorMessage", errorMsg, { root: true });
          }
        },
        error: (error: any) => {
          console.error(error);
        },
      };

      dispatch("loginByGoogle", { ...authData, ...options });
    },
    async signup(
      { commit, dispatch }: { commit: any; dispatch: any },
      authData: { email: string; validate?: any }
    ): Promise<any> {
      // @todo user can"t login with this method must use invitation
      commit("error", false);
      const newAuthData = _.assign({}, authData);
      newAuthData.validate = async (email: string): Promise<any> => {
        const valid = !(await dispatch("user/hasUser", email, {
          root: true,
        }));
        if (!valid) {
          commit(
            "auth/errorMessage",
            "An account with the given email already exists",
            { root: true }
          );
        }
        return valid;
      };
      dispatch("login", newAuthData);
    },
    async bindAccount(
      {
        commit,
        dispatch,
        rootState,
      }: { commit: any; dispatch: any; rootState: any },
      authData: { email: string; success?: any; error?: any }
    ): Promise<any> {
      const noop = () => {
        /* noop */
      };
      const errorHandler = _.isFunction(authData.error) ? authData.error : noop;
      const options = {
        success: async (result: any) => {
          const email = helpers.filterEmail(result.user.email);
          const valid = helpers.validateEmail(email);
          if (valid) {
            const email = _.get(result, "user.email");
            const hasUser = await dispatch("user/hasUser", email, {
              root: true,
            });
            if (hasUser) {
              const errorMsg = "An account with the given email already exists";
              commit("auth/errorMessage", errorMsg, { root: true });
              errorHandler(errorMsg);
              return false;
            }

            const currentUser = _.get(rootState, "user.user");
            if (!currentUser) {
              const errorMsg =
                "You are not a guest account. Please login with guest account to verify your email";
              commit("errorMessage", errorMsg);
              errorHandler(errorMsg);
              return;
            }

            if (!currentUser.isGuest) {
              const errorMsg =
                "You are not a guest account. Please login with guest account to verify your email";
              commit("errorMessage", errorMsg);
              errorHandler(errorMsg);
              return;
            }

            // update user
            await dispatch(
              "user/saveUser",
              {
                email: currentUser.email,
                emailTo: email,
              },
              { root: true }
            );
            _.set(rootState, "user.user.emailTo", email);

            await dispatch(
              "guest/transferGuestToUser",
              {
                guestEmail: currentUser.email,
                email: email,
              },
              { root: true }
            );

            await dispatch("upsertAccount", result);

            await dispatch(
              "auth/loginAsUser",
              { email: email },
              {
                root: true,
              }
            );
          } else {
            const errorMsg = `The account you attempted to log in with is not registered with MyCulture. Please try again or contact your admin.`;
            commit("auth/errorMessage", errorMsg, { root: true });
            errorHandler(errorMsg);
          }
        },
        error: (error: any) => {
          if (_.isFunction(authData.error)) {
            authData.error(error);
          }
        },
      };
      dispatch("loginByGoogle", options);
    },
  },
};
